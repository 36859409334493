import React, { useState, useEffect } from "react";
import logo from "../static/images/logo/Logo.svg";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { RiMenu3Fill } from "react-icons/ri";
import { FaWhatsapp } from "react-icons/fa";

const navigationItems = ["Home", "About", "Brands", "Contact"];

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the user has scrolled down 50 pixels or more
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = useNavigate();
  const [state, setState] = React.useState({
    right: false,
  });

  const links = [
    { name: "Home", href: "/" },
    { name: "About", href: "/about" },
    { name: "Brands", href: "/brands" },
    { name: "Contact", href: "/contact" },
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleNavigation = (path) => {
    if (path === "close") {
      toggleDrawer("right", false)(); // Close the drawer
    } else {
      navigate(path);
      toggleDrawer("right", false)(); // Close the drawer after navigation
    }
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        backgroundColor: "#171717", // Set background to black
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="text-white">
        {" "}
        {/* Set text color to white */}
        <ListItem disablePadding>
          <a
            href="/"
            className="flex items-center justify-center pt-5 ps-[4.5rem] pb-10"
          >
            <img src={logo} alt="Logo" className="h-16" />
          </a>
        </ListItem>
        <div className="ps-5">
          {navigationItems.map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() =>
                  handleNavigation(
                    text === "Close"
                      ? "close"
                      : text === "Home"
                      ? "/"
                      : `/${text.toLowerCase()}`
                  )
                }
                className="text-xl text-white" // Set button text color to white
              >
                <ListItemText
                  primary={text}
                  primaryTypographyProps={{
                    style: { fontSize: "0.875rem", color: "white" },
                  }} // Set ListItemText color to white
                />
              </ListItemButton>
            </ListItem>
          ))}
          <ListItem key="Chat with us" disablePadding>
            <ListItemButton
              component="a"
              href="https://wa.me/97477715423"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                backgroundColor: "#dc2626", // Tailwind's red-600 color
                color: "white",
                marginLeft: "10px",
                maxWidth: "125px",
                maxHeight: "40px",
                boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                "&:hover": {
                  backgroundColor: "white",
                  color: "#dc2626",
                },
                border: "1px solid #dc2626",
                fontSize: "0.675rem",
                fontWeight: "600",
                marginTop:'14px'
              }}
            >
              <ListItemText
                primary="Chat with us"
                primaryTypographyProps={{ style: { color: "inherit" } }}
              />
            </ListItemButton>
          </ListItem>
        </div>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <a href="/" className="p-4 text-[10px] text-center text-white">
        {" "}
        {/* Set text color to white */}
        Powered by <span className="text-emerald-500">Teqbae</span> innovations
        & solutions (India) pvt ltd
      </a>
    </Box>
  );

  return (
    <div>
      <div className="fixed top-0 left-0 w-full z-50 bg-black">
        <div className="pt-2 flex h-20 justify-between items-center px-6">
          <div>
            <a href="/">
              <img src={logo} className="h-16 -mt-3" alt="Logo" />
            </a>
          </div>
          <div className="hidden lg:flex items-center">
            <div className="flex items-center justify-between flex-grow">
              <div className="flex space-x-8">
                {links.map((link, index) => (
                  <a key={index} href={link.href} className="text-white hover:text-red-500">
                    {link.name}
                  </a>
                ))}
                <a
                  href="https://wa.me/97477715423"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-red-600 hover:bg-red-500 border hover:border-red-500 border-red-600 -mt-2 font-semibold text-white text-sm px-4 py-2 shadow-lg "
                >
                  Chat with us
                </a>
              </div>
            </div>
          </div>
          <div className="lg:hidden flex items-center">
            <Button onClick={toggleDrawer("right", true)}>
              <RiMenu3Fill className="text-white" size={22} />
            </Button>
            <SwipeableDrawer
              anchor="right"
              open={state["right"]}
              onClose={toggleDrawer("right", false)}
              onOpen={toggleDrawer("right", true)}
            >
              {list("right")}
            </SwipeableDrawer>
          </div>
        </div>
        <div>
          <a
            href="https://wa.me/97477715423"
            target="_blank"
            rel="noopener noreferrer"
            className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
            style={{ fontSize: "24px" }}
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
