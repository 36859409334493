import React from "react";
import Navbar from "../navbar/Navbar";
import OurBrands from "../home/OurBrands";
import Footer from "../footer/Footer";
import bg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";
import { FaWhatsapp } from "react-icons/fa";

import brand1 from "../static/images/brands/brand1.png";
import brand2 from "../static/images/brands/brand2.png";
import brand3 from "../static/images/brands/brand3.png";
import brand4 from "../static/images/brands/brand4.png";
import brand5 from "../static/images/brands/brand5.png";
import brand6 from "../static/images/brands/brand6.png";
import brand7 from "../static/images/brands/brand7.png";
import brand8 from "../static/images/brands/brand8.png";
import brand9 from "../static/images/brands/NTF brand logo.png";
import brand10 from "../static/images/brands/brand10.png";
import brand11 from "../static/images/brands/macpan.svg";
import brand12 from "../static/images/brands/macno 1.png";
import brand13 from "../static/images/brands/LOGO.webp";
import brand14 from "../static/images/brands/logo-header.png";
import brand15 from "../static/images/brands/fimar.png";
import brand17 from "../static/images/brands/Roller-Grill-Logo 1.png";
import brand18 from "../static/images/brands/Berjaya-Logo.png";
import brands19 from "../static/images/brands/Rational_AG_201x_logo.svg.png";
import brands20 from "../static/images/brands/brand16.png";
import brands21 from "../static/images/brands/BAT_LOGO_CAPLAIN_MACHINES_RVB-HORIZ-768x201.png";
import brands22 from "../static/images/brands/logo-2020-lainox2.png";

const icons = [
  brand1,
  brand2,
  brand3,
  brand4,
  brand5,
  brand6,
  brand7,
  brand10,
  brand11,
  brand13,
  brand15,
  brand18,
  brands19,
  brands21,
];

const icons2 = [brands20, brand9, brand12, brand8];

const icons3 = [brand14, brand17];

function Brands() {
  return (
    <div className="bg-black">
      <div>
        <Navbar />
      </div>
      <div className="-mt-2 pb-10 bg-black">
        <div className="relative flex flex-col h-96 md:h-full">
          <img
            src={bg}
            className="w-full h-[120%] object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-10">
            <div className="w-full p-8">
              {/* <p className="text-xl lg:text-4xl font-semibold text-white text-center">
                OUR BRANDS
              </p> */}
            </div>
          </div>
        </div>
        <div className="text-white pt-10 lg:pt-20 px-10 lg:px-32 bg-black">
          <div className="bg-red-600 w-72 px-5 py-5 font-semibold hidden lg:block absolute right-28 -mt-32">
            <p className="text-white">
              Star Kitchen Equipments WLL brands Portfolio
            </p>
          </div>
          <div className="bg-black">
            <p className="text-3xl lg:text-4xl font-semibold text-white text-left">
              Our Brands
            </p>
            <div className="line mt-2 bg-red-600 h-0.5 w-1/2 md:w-1/3 lg:w-1/5"></div>

            {/* <p className="text-2xl font-semibold lg:3xl lg:pt-7">Brands</p> */}
            <p className="text-sm lg:text-base pt-5 text-neutral-300 lg:tracking-wide">
              At Star Kitchen Equipments WLL, we take pride in partnering with
              renowned internationalbrands to ensure our clients have access to
              the best-in-class commercial kitchen solutions.Our carefully
              curated portfolio encompasses a diverse range of brands, each
              known for its innovation, quality, and reliability.
            </p>
          </div>
        </div>

        <div className="bg-black md:h-full h-screen mt-10 mb-32 xs:mb-36">
          <OurBrands showReadMore={false}/>
        </div>
        <div className="bg-black mt-28 sc375:mt-72 iphoneX:mt-40 iphone12:mt-36 iphone14promax:mt-10 iphone13promax:mt-5 sc432:mt-0 sc396:mt-14 xs:mt-16 sc460:mt-0 sm:mt-16 md:mt-44 lg:mt-40">
          <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5 gap-12 lg:gap-y-[4rem] xs:pt-16 md:pt-16 px-10 lg:px-32">
            {icons.map((icon, index) => (
              <div
                key={index}
                className="flex lg:h-14 justify-center items-center"
              >
                <img
                  src={icon}
                  alt={`Brand ${index + 1}`}
                  className="h-8 w-auto"
                />
              </div>
            ))}
            {icons2.map((icon, index) => (
              <div key={index} className="flex justify-center items-center">
                <img
                  src={icon}
                  className="max-w-full max-h-8 lg:max-h-10 object-contain"
                />
              </div>
            ))}
            {icons3.map((icon, index) => (
              <div key={index} className="flex justify-center items-center">
                <img
                  src={icon}
                  className="max-w-full max-h-8 lg:max-h-12 object-contain"
                />
              </div>
            ))}
            <div className="flex justify-center items-center md:hidden">
              <img
                src={brands22}
                className="max-w-full max-h-8 lg:max-h-10 object-contain"
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      <div>
        <a
          href="https://wa.me/97477715423"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
          style={{ fontSize: "24px" }}
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default Brands;
