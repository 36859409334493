import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";
import bg from "../static/images/modern-kitchen-equipment-in-stainless-steel-design-free-photo (1).jpg";
import about1 from "../static/images/about/about1.jpg";
import about2 from "../static/images/about/about2.jpg";
import captain from "../static/images/about/md.jpg";
import team from "../static/images/about/full group.jpg";
import { FaWhatsapp } from "react-icons/fa";

import client1 from "../static/images/client/client1.png";
import client2 from "../static/images/client/client2.png";
import client3 from "../static/images/client/client3.png";
import client4 from "../static/images/client/almeera logo.svg";
import client5 from "../static/images/client/Samsung-logo.png";
import client6 from "../static/images/client/lulu.png";
import client7 from "../static/images/client/Katara-Hospitality logo.svg";
import client8 from "../static/images/client/logo@2x.png";
//import client9 from "../static/images/client/1790be_e2ca2c6e58564a8aa18340280a08f45d~mv2.png";
//import client10 from "../static/images/client/Font-Nandos-Logo.jpg";
import client11 from "../static/images/client/w-hotels-1.svg";
//import client13 from "../static/images/client/QCC_Logo.png";
//import client14 from "../static/images/client/ABA-Contracting-Logo.png";
//import client15 from "../static/images/client/Papa_John's_Logo_2019.svg";
import client16 from "../static/images/client/Grand-Hyatt-Logo.webp";
import client17 from "../static/images/client/compass-group-logo_2-e1530788066622.webp";
import client18 from "../static/images/client/m hotel.png";
import client19 from "../static/images/brands/baskin-robbins-logo-vector-removebg-preview.png";
import client21 from "../static/images/client/SAgUgLf6_400x400.png";

const clients = [
  client2,
  client3,
  client4,
  client1,
  client5,
  client6,
  client7,
  client8,
  client16,
  //client18
];

function AboutUs() {
  const { ref: counterRef, inView: isCounterVisible } = useInView({
    triggerOnce: true, // Counter will animate only once
  });

  return (
    <div className="bg-black">
      <div>
        <Navbar />
      </div>
      <div className="-mt-2">
        <div className="relative flex flex-col h-96 md:h-full">
          <img
            src={bg}
            className="w-full h-[120%] object-cover"
            alt="Background"
          />
          <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-10">
            <div className="w-full p-8">
              {/* <p className="text-xl lg:text-4xl font-semibold text-white text-center">
                About Us
              </p> */}
            </div>
          </div>
        </div>
        <div className="text-white pt-10 lg:pt-20 px-10 lg:px-32 bg-black">
          <div className="bg-red-600 w-72 h-24 px-5 py-5 font-semibold hidden lg:block absolute right-28 -mt-32">
            <p className="text-white text-sm -mt-1">
              The strong manufacture base & the manufacturing network we own
              worldwide,
            </p>
          </div>
          <div>
            <p className="text-3xl lg:text-4xl font-semibold text-white text-left">
              About Us
            </p>
            <div className="line mt-2 bg-red-600 h-0.5 w-1/2 md:w-1/3 lg:w-1/5"></div>
            <p className="text-2xl pt-3 font-semibold lg:3xl lg:pt-6 -pb-2">
              Overview
            </p>
            {/* <div className="line mt-2 bg-red-600 h-0.5 w-1/2 md:w-1/3 lg:w-1/5"></div> */}
            <p className="text-sm lg:text-base pt-2 text-neutral-300 lg:tracking-wide">
              Star Kitchen Equipments is a dominant player in the world of
              commercial kitchen equipment for a decade from 2009 in the State
              of Qatar. Our factory in Qatar manufactures high-quality equipment
              to satisfy the Dream and deas of our clients. The strong
              manufacture base and the manufacturing network we own worldwide,
              and we are sole agent for some of the world's most renowned
              kitchen and laundry equipment manufacturers and supplier from
              Europe, U.S.A. & Asia
            </p>
          </div>
          <div className="lg:px-20">
            <div className="flex flex-col lg:flex-row w-full pt-10">
              <p className="text-2xl font-semibold lg:3xl lg:hidden">Mission</p>
              <div className="pt-2 lg:w-1/2">
                <img
                  src={about2}
                  className="h-48 md:h-96 lg:h-80 w-[100%] lg:w-[90%]"
                  alt="About Image"
                />
              </div>

              <div className="lg:ps-10 lg:w-1/2 flex flex-col justify-center ">
                <p className="text-2xl lg:3xl -ml-10 font-semibold hidden lg:block">
                  Mission
                </p>
                <p className="text-sm lg:text-base pt-5 lg:-ml-10 text-neutral-300 lg:tracking-wide">
                  At Star Kitchen Equipments WLL, our mission is to provide
                  unparalleled quality measures to our clients. We are dedicated
                  to staying at the forefront of industry trends and
                  technologies, ensuring that our offerings are not only
                  cutting-edge but also aligned with the evolving needs of our
                  clientele. We strive to build lasting relationships with our
                  clients through attentive care and superior service,
                  consistently exceeding expectations in the world of commercial
                  kitchen equipment.
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row w-full pt-10 ">
              <p className="text-2xl font-semibold lg:3xl lg:hidden">Vision</p>
              <div className="lg:pe-10 lg:w-1/2 flex flex-col justify-center">
                <p className="text-2xl font-semibold lg:3xl hidden lg:block">
                  Vision
                </p>
                <div className="pt-2 lg:w-1/2 lg:hidden">
                  <img
                    src={about1}
                    className="h-48 md:h-96 lg:h-80 w-[100%]"
                    alt="About Image"
                  />
                </div>
                <p className="text-sm lg:text-base pt-5 text-neutral-300 lg:tracking-wide	">
                  Our vision is to be a trailblazer in the global commercial
                  kitchen equipment industry, setting the standard for
                  innovation, quality, and client satisfaction. We aspire to be
                  recognized as the go-to partner for institutions seeking
                  state-of-the-art solutions. Through continuous adaptation to
                  emerging trends and technologies, we envision Star Kitchen
                  Equipments WLL as a symbol of excellence, synonymous with
                  reliability, creativity, and unwavering commitment to client
                  success.
                </p>
              </div>
              <div className="pt-2 lg:w-1/2 hidden lg:block">
                <img
                  src={about1}
                  className="h-48 md:h-96 lg:h-80 w-[100%] "
                  alt="About Image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row lg:ml-32 bg-black pt-10">
          <div
            ref={counterRef}
            className="bg-red-500 text-white h-24 lg:w-72 lg:h-20 flex flex-col justify-center items-center mx-2"
          >
            <p className="text-2xl lg:text-3xl font-bold">
              {isCounterVisible && <CountUp end={2000} duration={2} />}+
            </p>
            <p className="text-sm">Happy Clients</p>
          </div>
          <div className="px-10 lg:px-5 lg:pe-20">
            <p className="text-white text-xl pt-5 lg:pt-0 font-semibold">
              Our Clients
            </p>
            <p className="pt-2 text-neutral-300 text-sm lg:text-base pr-8">
              With over 2000 happy and satisfied clients, we are empowered to
              take on any challenges and prove our competence and worth. Our
              strong client relationships and post-sales support instill
              confidence in our clients, encouraging them to consult us for
              their future projects and alterations.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-4 gap-10 lg:gap-20 pt-10 lg:pt-20 bg-black px-10 lg:px-20 pb-10">
          {clients.map((client, index) => (
            <div key={index} className="flex justify-center items-center">
              <img
                src={client}
                alt={`Client ${index + 1}`}
                className="max-w-full max-h-20 lg:max-h-10 object-contain"
              />
            </div>
          ))}

          <div className="flex justify-center items-center">
            <img
              src={client19}
              className="max-w-full max-h-20 lg:max-h-16 object-contain"
            />
          </div>
          {/* Conditionally render client18 or client21 based on screen size */}
          <div className="flex justify-center items-center md:hidden">
            <img
              src={client18}
              alt="Client 18"
              className="w-full max-h-20 object-contain"
            />
          </div>
          <div className="hidden md:block">
            <div className="flex justify-center items-center">
              <img
                src={client18}
                alt="Client 21"
                className="max-h-20 object-contain"
              />
            </div>
          </div>

          <div className="flex justify-center items-center md:hidden">
            <img
              src={client11}
              alt="Client 18"
              className="w-full max-h-20 object-contain"
            />
          </div>
          <div className="hidden md:block">
            <div className="flex justify-center items-center">
              <img
                src={client11}
                alt="Client 21"
                className="max-h-[6.5rem] object-contain"
              />
            </div>
          </div>
          {/* <div className="flex justify-center items-center">
            <img
              src={client4}
              className="max-w-full max-h-24 lg:max-h-10 object-contain"
            />
          </div> */}
        </div>

        <div className="px-10 lg:px-16 xl:px-32 text-white">
          <div className="flex flex-col lg:flex-row lg:justify-between w-full pt-10 lg:pt-0">
            <p className="text-2xl font-semibold lg:3xl lg:hidden">
              Our Chairman
            </p>
            <div className="lg:pe-10 xl:pe-0 lg:w-1/2 flex flex-col justify-center">
              <p className="text-2xl font-semibold lg:3xl hidden lg:block">
                Our Chairman
              </p>
              <div className="pt-2 lg:me-10 lg:w-1/2 lg:hidden">
                <img
                  src={captain}
                  className="xs:h-52 h-60 md:h-96 lg:h-60 xl:h-80 w-[100%]"
                  alt="About Image"
                />
              </div>
              <p className="text-sm lg:text-base pt-5 text-neutral-300 lg:tracking-wide	">
                We are committed to leading the way in industry trends and
                technologies, ensuring our solutions are both innovative and in
                tune with the changing demands of our clients. Our focus is on
                building enduring relationships by providing exceptional care
                and top-notch service, consistently surpassing expectations in
                the realm of commercial kitchen equipment.
              </p>
            </div>
            <div className="pt-2 lg:w-1/3 xl:w-1/3 hidden lg:block">
              <div className="bg-red-600 absolute w-72 h-20 px-5 py-5 lg:mt-6 xl:mt-6 right-[25%] 2xl:right-[27%]">
                <p className=" text-white text-sm ">
                  Choose Star Kitchen Equipments WLL for a comprehensive suite
                  of services
                </p>
              </div>
              <div className="">
                <img
                  src={captain}
                  className="h-full w-full"
                  alt="About Image"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row w-full pt-10">
            <p className="text-2xl font-semibold lg:3xl lg:hidden">Our Team</p>
            <div className="lg:w-1/2">
              <img src={team} className="h-full w-[100%] " alt="About Image" />
            </div>
            <div className="lg:ps-10 flex flex-col justify-center lg:w-1/2">
              <p className="text-2xl lg:3xl font-semibold hidden lg:block">
                Our Team
              </p>
              <p className="text-sm lg:text-base pt-5 text-neutral-300 lg:tracking-wide">
                Our goal is to be acknowledged as the trusted partner for
                institutions in search of cutting-edge solutions. By
                consistently embracing new trends and technologies, we aim for
                Star Kitchen Equipments WLL to stand as a beacon of excellence,
                known for reliability, innovation, and a steadfast commitment to
                our clients' success.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
      {/* WhatsApp Chat Button */}
      <div>
        <a
          href="https://wa.me/97477715423"
          target="_blank"
          rel="noopener noreferrer"
          className="fixed bottom-6 right-4 bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600"
          style={{ fontSize: "24px" }}
        >
          <FaWhatsapp />
        </a>
      </div>
    </div>
  );
}

export default AboutUs;
