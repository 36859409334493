import React, { useState, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import brand1 from "../static/images/brand1.png";
import brand2 from "../static/images/brand2.png";
import brand3 from "../static/images/brand3.png";
import brand1logo from "../static/images/brand1_logo.png";
import brand2logo from "../static/images/brand2_logo.png";
import brand3logo from "../static/images/brand3_logo.png";
import bg from "../static/images/our brand bg.png";

const brands = [
  {
    title1: "Dihr",
    title2: "Kitchen",
    dis: "At Dihr Kitchen, we combine innovative design with superior craftsmanship to bring you top-quality kitchen equipment.",
    img: brand3,
    logo: brand1logo,
  },
  {
    title1: "Berto's",
    title2: "Kitchen",
    dis: "Berto's Kitchen offers a range of premium kitchen tools and appliances, crafted to meet the demands of professional chefs.",
    img: brand1,
    logo: brand3logo,
  },
  {
    title1: "Fagor",
    title2: "Professional",
    dis: "At Fagor Professional, we are world leaders in manufacturing equipment for the hospitality, catering, and laundry sectors.",
    img: brand2,
    logo: brand2logo,
  },
];

const OurBrands  = ({ showReadMore }) => {
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  const nextBrand = () => {
    setCurrentBrandIndex((prevIndex) => (prevIndex + 1) % brands.length);
  };

  const prevBrand = () => {
    setCurrentBrandIndex((prevIndex) =>
      prevIndex === 0 ? brands.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    const interval = setInterval(nextBrand, 2000);
    return () => clearInterval(interval);
  }, []);

  const { title1, title2, dis, img, logo } = brands[currentBrandIndex];

  return (
    <div className="flex-1 flex items-center justify-center h-96 relative">
      <div className="relative w-full h-[320px]">
        <img src={bg} alt="Background" className="w-full h-full object-cover" />
        <div className="absolute inset-0 mt-10 flex items-center justify-center p-4">
          <div className=" flex flex-col md:flex-row h-[350px] w-full max-w-4xl rounded-lg">
            <div className="flex-1 h-full md:overflow-hidden flex flex-col items-center justify-center md:items-start md:justify-start">
              <p className="text-[#B3B3B3] text-3xl text-center pt-24 md:hidden">
                OUR BRANDS
              </p>
              <p className="text-white text-center md:hidden pt-1 mb-10 text-sm md:text-lg px-4">
                Discover our top brands with excellence and innovation.
              </p>
              <img
                src={img}
                alt={title1}
                className="h-auto max-h-full w-auto object-cover"
              />
            </div>
            <div className="flex-1 sm:bg-transparent mt-12 lg:pt-0 px-16 p-4 ">
              <div className="flex flex-col h-full justify-between">
                <button
                  onClick={prevBrand}
                  className="absolute left-6 -bottom-96 md:left-72 md:top-72 lg:left-[45%] lg:top-64 transform -translate-y-1/2 text-4xl text-white p-3 rounded-full"
                >
                  <IoIosArrowBack />
                </button>
                <div>
                  <p className="text-[#B3B3B3] text-3xl hidden md:block">
                    {" "}
                    OUR BRANDS
                  </p>
                  <p className="text-2xl md:text-5xl pt-16 md:pt-5 text-white text-center md:text-left font-bold uppercase">
                    {title1}
                  </p>
                  <p className="text-2xl md:text-5xl text-white pb-2 text-center md:text-left font-bold mb-2 uppercase">
                    {title2}
                  </p>
                  <div className="line mt-4 bg-red-600 h-0.5"></div>
                  <p className="text-white text-center md:text-left xs:pt-5 pt-5 ">
                    {dis}
                  </p>

                  <div className="flex justify-center md:justify-start pt-5">
                    <img
                      src={logo}
                      alt={title1}
                      className="h-10 md:h-12 w-auto object-cover"
                    />
                  </div>
                  {showReadMore && (
                    <div className="w-full flex justify-center md:justify-start pt-6 md:pt-6">
                      <a
                        href="/about"
                        className="bg-red-600 hover:text-red-600 hover:bg-black border border-red-600 font-semibold text-white px-4 py-2 mt-2"
                      >
                        Read More
                      </a>
                    </div>
                  )}
                </div>
                <button
                  onClick={nextBrand}
                  className="absolute right-6 -bottom-96 md:right-10 md:top-72 lg:right-80 lg:top-64 transform -translate-y-1/2 text-4xl text-white p-3 rounded-full"
                >
                  <IoIosArrowForward />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurBrands;
